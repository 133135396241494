import type {ReactElement} from 'react';
import React from 'react';
import styles from './source-tag.module.scss';

export interface SourceTagProps {
  containerStyle?: string;
  contentStyle?: string;
}

export function SourceTag({...props}: SourceTagProps): ReactElement {
  return (
    <div className={`spacing-p-t-1 spacing-p-b-1 spacing-p-r-1 spacing-p-l-2 flex-center ${styles.container} ${props.containerStyle ?? ''}`}>
      <div className={`_full-height flex-center ${styles.content} ${props.contentStyle ?? ''}`} />
    </div>
  );
}
