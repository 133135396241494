import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import styles from './cost-tag.module.scss';

export interface CostTagProps {
  icon: string;
  price: string;
  textStyle?: string;
  containerStyle?: string;
}

export function CostTag({...props}: CostTagProps): ReactElement | null {
  return (
    <div className={`spacing-p-t-1 spacing-p-b-1 spacing-p-r-1 spacing-p-l-2 flex-center ${styles.container} ${props.containerStyle ?? ''}`}>
      <Icon icon={props.icon} size={IconSize.SIZE_ICON_16} />
      <Text disableSelection size={TextSize.XXSMALL} val={props.price} className={`-truncated-text ${styles.pricingText} ${props.textStyle ?? ''}`} />
    </div>
  );
}
