"use strict";
/**
 * This library contains common upload functions
 * @author Hamza Wain <hamza@250mils.com>
 */
import {eventProgressHandler} from "./loading-toast-library.js";

/**
 * Max side in pixels of the thumbnail created in javascript
 */
const MAX_THUMB_SIDE = 150;

/**
 * Removes the handler attached for uploading
 * @param {JQuery<HTMLInputElement>} fileUploadInput upload input
 * window.File and/or window.FileList, informing the user that their browser does not support upload. Should always be the function
 * showUnableToUploadError in AppMediator
 * @param {string} [eid] ID selector of the element to setup drag and drop on
 */
export function removeUploadHandlers(fileUploadInput, eid) {
    if (window.File && window.FileList) {
        let el = $(eid),
            ddo = el.find('.dragdropoverlay');

        // file select
        if (fileUploadInput && fileUploadInput.length) {
            fileUploadInput.off('change');
        }

        if (typeof eid !== 'undefined') {
            let xhr = new XMLHttpRequest();
            if (xhr.upload) {
                el.off('dragover');
                el.off('drop');
                ddo.off('dragleave');
            }
        }
    } else {
        if (fileUploadInput && fileUploadInput.length) {
            fileUploadInput.off('click')
        }
    }
}

/**
 * Initializes AJAX upload, optionally with drag drop
 * @param {JQuery<HTMLInputElement>|null} fileUploadInput upload input
 * @param {function} uf The upload function to process the files
 * @param {function} ecb Error callback function assigned to the click of the input[type=file] for when the UA does not support
 * window.File and/or window.FileList, informing the user that their browser does not support upload. Should always be the function
 * showUnableToUploadError in AppMediator
 * @param {string} [eid] ID selector of the element to setup drag and drop on
 * @param {string} [msg] The message to display on the drag-drop overlay
 */
export function initUpload(fileUploadInput, uf, ecb, eid, msg) {
    if (window.File && window.FileList) {
        let el = $(eid),
            ddo = el.find('.dragdropoverlay');

        // file select
        if (fileUploadInput && fileUploadInput.length) {
            fileUploadInput.on('change', onFileSelectHandler.bind(this, uf, ddo));
        }

        if (typeof eid !== 'undefined') {
            // is XHR2 available?
            let xhr = new XMLHttpRequest();
            if (xhr.upload) {

                // set up drag and drop events
                el.on('dragover', onFileDragHover.bind(this, ddo))
                    .on('drop', onFileSelectHandler.bind(this, uf, ddo));
                ddo.on('dragleave', onFileDragHover.bind(this, ddo))
                    .find('.message').html(msg);
            }
        }
    } else {
        if (fileUploadInput && fileUploadInput.length) {
            fileUploadInput.on('click', function (e) {
                e.preventDefault();
                ecb();
            })
        }
    }
}

/**
 * Custom callback for creating the XMLHttpRequest object which wires up to the progress of the upload
 * @param {string} loadingKey .progress-bar div associated with the upload
 * @returns {XMLHttpRequest}
 */
export const progressHandler = (loadingKey) => {
    let myXhr = $.ajaxSettings.xhr();
    if (myXhr.upload) {
        myXhr.upload.addEventListener('progress', eventProgressHandler.bind(null, loadingKey), false);
    }
    return myXhr;
}

/**
 * Returns an image thumbnail for file
 * @param file
 * @return {Promise<string>}
 */
export const getThumbnailSrcFromFile = async file => {
    return new Promise((resolve, reject) => {
        // If the UA doesn't support FileReader, then we won't create a thumbnail, rather wait for the upload
        // and populate the grid-item with the image from the server
        if (window.FileReader) {
            let reader = new FileReader();
            reader.onerror = reader.onabort = reject;
            reader.onload = e => {
                resolve(createThumb(file, e.target.result));
            };
            reader.readAsDataURL(file);
        } else {
            resolve(null);
        }
    });
}

/**
 * Sends request to server to check whether the user video is ready
 * @param {string} userVideoHashedId
 * @param {postermywall.core.model.UserProxy} up
 * @param {function} onSuccess on success function
 * @param {function} onError on error function function
 */
function isUserVideoReady(userVideoHashedId, up, onSuccess, onError) {
    up.getUserVideoStatus(userVideoHashedId)
        .done(onIsUserVideoReadySuccess.bind(this, userVideoHashedId, up, onSuccess, onError))
        .fail(onError);
}

/**
 * Success callback for isUserVideoReady. Either the user video is ready or still in processing state
 * @param {string} userVideoHashedId
 * @param {{postermywall.core.model.UserProxy}} up
 * @param {function} onSuccess on success function
 * @param {function} onError on error function function
 * @param {Object} r
 */
function onIsUserVideoReadySuccess(userVideoHashedId, up, onSuccess, onError, r) {
    if (r.status === 'success' && r.data) {
        switch (r.data.status) {
            case 'available':
                onSuccess(r.data.data);
                break;

            case 'processing':
                isUserVideoReady(userVideoHashedId, up, onSuccess, onError);
                break;

            default:
                onError();
        }
    } else {
        onError();
    }
}

/**
 * File drag over handler. Displays "Drop files here..." overlay on dragover
 * @param {jQuery} ddo jQuery object for the Drag and Drop overlay
 * @param {Object} e EventData
 */
function onFileDragHover(ddo, e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === 'dragover') {
        ddo.show();
    } else {
        ddo.hide();
    }
}

/**
 * File select handler.
 * @param {function} uf The upload function to process the files
 * @param {jQuery} ddo jQuery object for the Drag and Drop overlay
 * @param {Object} e EventData
 */
function onFileSelectHandler(uf, ddo, e) {
    // cancel event and hover styling
    if (ddo.length) {
        onFileDragHover(ddo, e);
    } else {
        e.stopPropagation();
        e.preventDefault();
    }

    // fetch FileList object
    var dt = e.dataTransfer || (e.originalEvent && e.originalEvent.dataTransfer);
    var fileList = e.target.files || (dt && dt.files);
    var files = [];

    // Push multiple files to an array and upload one by one
    $.each(fileList, function (key, file) {
        files.push(file);
    });

    // Clear the input field in case a user wants to reupload the same file
    var input = $(e.currentTarget);
    input.wrap('<form>').closest('form').get(0).reset();
    input.unwrap();

    uf(files);
}

/**
 * Creates a thumbnail of the file passed. Draws the thumbnail on a canvas and passes
 * back an <img> element.
 * @param {Object} file Image file to create the thumbnail of
 * @param {string} imageUrl JavaScript URL to the file
 * @return {Promise<string>}
 */
function createThumb(file, imageUrl) {
    return new Promise((resolve, reject) => {
        let img = document.createElement("img");

        if (file.type === 'image/svg+xml') {
            return imageUrl;
        }

        img.onload = () => {
            let temp = document.createElement("img"),
                canvas, ctx, resizeInfo, _ref, _ref1, _ref2, _ref3;

            temp.src = img.src;
            file.width = temp.width;
            file.height = temp.height;
            resizeInfo = resize(file);
            if (resizeInfo.trgWidth == null) {
                resizeInfo.trgWidth = resizeInfo.optWidth;
            }
            if (resizeInfo.trgHeight == null) {
                resizeInfo.trgHeight = resizeInfo.optHeight;
            }
            canvas = document.createElement("canvas");
            ctx = canvas.getContext("2d");
            canvas.width = resizeInfo.trgWidth;
            canvas.height = resizeInfo.trgHeight;
            drawImageIOSFix(ctx, img, (_ref = resizeInfo.srcX) != null ? _ref : 0, (_ref1 = resizeInfo.srcY) != null ? _ref1 : 0, resizeInfo.srcWidth, resizeInfo.srcHeight, (_ref2 = resizeInfo.trgX) != null ? _ref2 : 0, (_ref3 = resizeInfo.trgY) != null ? _ref3 : 0, resizeInfo.trgWidth, resizeInfo.trgHeight);
            resolve(canvas.toDataURL("image/png"));
        }

        img.onerror = img.onabort = reject
        img.src = imageUrl;
        return img;
    });
}

function resize(file) {
    var info, srcRatio, ms;
    info = {
        srcX: 0,
        srcY: 0,
        srcWidth: file.width,
        srcHeight: file.height
    };
    ms = MAX_THUMB_SIDE;
    srcRatio = file.width / file.height;

    info.optWidth = ms;
    info.optHeight = ms / srcRatio;
    if (info.optHeight > ms) {
        info.optHeight = ms;
        info.optWidth = ms * srcRatio;
    }

    return info;
}

/**
 * Helper function for drawImageIOSFix
 * @param {Object} img
 * @returns {number}
 */
function detectVerticalSquash(img) {
    let alpha, canvas, ctx, data, ey, ih, iw, py, ratio, sy;
    iw = img.naturalWidth;
    ih = img.naturalHeight;
    canvas = document.createElement("canvas");
    canvas.width = 1;
    canvas.height = ih;
    ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    data = ctx.getImageData(0, 0, 1, ih).data;
    sy = 0;
    ey = ih;
    py = ih;
    while (py > sy) {
        alpha = data[(py - 1) * 4 + 3];
        if (alpha === 0) {
            ey = py;
        } else {
            sy = py;
        }
        py = (ey + sy) >> 1;
    }
    ratio = py / ih;
    if (ratio === 0) {
        return 1;
    } else {
        return ratio;
    }
}

/**
 * Helper function to fix images for retina
 * @param {CanvasRenderingContext2D} ctx
 * @param {Element} img
 * @param {number} sx
 * @param {number} sy
 * @param {number} sw
 * @param {number} sh
 * @param {number} dx
 * @param {number} dy
 * @param {number} dw
 * @param {number} dh
 * @returns {object}
 */
function drawImageIOSFix(ctx, img, sx, sy, sw, sh, dx, dy, dw, dh) {
    let vertSquashRatio;
    vertSquashRatio = detectVerticalSquash(img);
    return ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh / vertSquashRatio);
}

export const onUnableToUploadError = () => {
    PMW.openErrorModal({
        message: i18next.t('pmwjs_cannot_upload_error_msg')
    })
}
