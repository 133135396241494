export enum RecorderMediaType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export enum ImageAndVideoRecorderStatus {
  LOADING_RECORDER = 'loading_recorder',
  READY_TO_RECORD = 'ready_to_record',
  STOPPED = 'stopped',
  RECORDING = 'recording',
}

export interface ImageAndVideoRecorderStatusStateSetterProps {
  imageAndVideoRecorderStatus: ImageAndVideoRecorderStatus;
}

export interface SelectedCameraStateSetterProps {
  selectedCamera: string | undefined;
}

export interface MediaTypeStateSetterProps {
  mediaType: RecorderMediaType;
}

export enum PermissionStates {
  DENIED = 'denied',
  GRANTED = 'granted',
  PROMPT = 'prompt',
  NOT_SUPPORTED = 'not supported',
}

export type PossiblePermissionStates = 'denied' | 'granted' | 'prompt' | 'not supported';
